import React, { useState, createContext, useContext, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Contact = createContext();
export function ContactCtxProvider({ children }) {
  const url = process.env.REACT_APP_API_BASEURL;
  const [department, setDepartment] = useState([]);
  const GetContactData = async () => {
    var requestOptions = {
      method: "GET",
    };

    fetch(`${url}web/contact-department?query=` + new Date().toLocaleString(), requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setDepartment(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetContactData();
  }, []);
  return (
    <Contact.Provider value={{ type: "CONTACT", department }}>
      {children}
    </Contact.Provider>
  );
}

export function useContactCtx() {
  return useContext(Contact);
}
