import React from "react";
import { HelmetProvider } from "react-helmet-async";
import TimeoutLogic from "./Pages/Login/TimeoutLogic";
import { Toaster } from "react-hot-toast";
import { ServiceCtxProvider } from "./Pages/Service/context";
import { AboutCtxProvider } from "./Pages/About/context";
import { CourseCategoryCtxProvider } from "./Pages/Course/CourseCategory/context";
import { ContactCtxProvider } from "./Pages/ContactUs/context";
import { HomeCtxProvider } from "./Pages/Home/context";
const AllRoutes = React.lazy(() => import("./Route/AllRoutes"));

export default function App() {
  const helmetContext = {};
  return (
    <React.Fragment>
      <ServiceCtxProvider>
        <AboutCtxProvider>
          <CourseCategoryCtxProvider>
            <ContactCtxProvider>
              <HomeCtxProvider>
                <HelmetProvider context={helmetContext}>
                  <React.Suspense fallback={""}>
                    <AllRoutes />
                  </React.Suspense>
                </HelmetProvider>
              </HomeCtxProvider>
            </ContactCtxProvider>
          </CourseCategoryCtxProvider>
        </AboutCtxProvider>
      </ServiceCtxProvider>
      <TimeoutLogic />
      <Toaster />
    </React.Fragment>
  );
}

