import { createContext, useContext, useEffect, useState } from "react";
import CategoryCourseService from "../../../Services/CourseService/CategoryCourseService";
import MetaService from "../../../Services/MetaServices/MetaService";
const CourseCategory = createContext();
export function CourseCategoryCtxProvider({ children }) {
  const metaService = new MetaService();
  const [meta, setMeta] = useState({
    title: "",
    keywords: "",
    description: "",
    breadcrumb: "",
  });
  const [data, setData] = useState({});
  const [softwareList, setSoftwareList] = useState([]);
  const [infrastructure, setInfrastructure] = useState([]);
  const [trendingCourse, setTrendingCourse] = useState([]);
  const serve = new CategoryCourseService();

  useEffect(() => {
    window.scroll(0, 0);
    SoftWareCategoryCourseListApi();
    trendingCourseListApi();
    infrastructureCategoryListApi();
    coursePageApi();
  }, []);

  const SoftWareCategoryCourseListApi = async () => {
    try {
      let response = await serve.softwarecategory();
      if (response) {
        setSoftwareList(response.data);
      } else {
      }
    } catch (err) {
      throw err;
    }
  };

  const infrastructureCategoryListApi = async () => {
    try {
      let response = await serve.infrastructurecategory();
      if (response) {
        setInfrastructure(response.data);
      } else {
      }
    } catch (err) {
      throw err;
    }
  };

  const trendingCourseListApi = async () => {
    try {
      let response = await serve.trndingcourse();
      if (response) {
        setTrendingCourse(response.data);
      } else {
      }
    } catch (err) {
      throw err;
    }
  };

  const coursePageApi = async () => {
    try {
      let response = await serve.staticPage("course");
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getmetaData("course");
  }, []);
  const getmetaData = async () => {
    try {
      let response = await metaService.getMetadetail("course");
      if (response.status === "success") {
        setMeta({
          title: response.data.meta_title,
          Keywords: response.data.meta_keywords,
          description: response.data.meta_description,
          breadcrumb: response.data.breadcrumb,
        });
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    <CourseCategory.Provider
      value={{
        meta,
        data,
        softwareList,
        infrastructure,
        trendingCourse,
        type: "COURSE CATEGORY",
      }}
    >
      {children}
    </CourseCategory.Provider>
  );
}
export function useCourseCategoryCtx() {
  return useContext(CourseCategory);
}
