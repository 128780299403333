import { createContext, useContext, useEffect, useState } from "react";
import AboutService from "../../Services/AboutusServices/AboutService";
const AboutCtx = createContext();
export function AboutCtxProvider({ children }) {
  const [data, setData] = useState({});
  const aboutServe = new AboutService();
  const whoWeAreApi = async () => {
    try {
      let response = await aboutServe.whoweare();
      if (response) {
        setData(response.data);
      }
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    whoWeAreApi();
  }, []);

 

  return (
    <AboutCtx.Provider value={{ type: "ABOUT", data }}>
      {children}
    </AboutCtx.Provider>
  );
}
export function useAboutCtx() {
  return useContext(AboutCtx);
}
