  import { useState, useContext, useEffect, createContext } from "react";
  import MetaService from "../../Services/MetaServices/MetaService";
  const Home = createContext();
  export function HomeCtxProvider({ children }) {
    const [data, setData] = useState();
    const metaService = new MetaService();
    const [meta, setMeta] = useState({
      title: "",
      keywords: "",
      description: "",
    });

    const getPageBlock = async () => {
      try {
        let response = await metaService.service("home");
        if (response.status === "success") {
          setData(response.data);
        }
      } catch (err) {
        throw err;
      }
    };
    // getmetaData
    const getmetaData = async () => {
      try {
        let response = await metaService.getMetadetail("home");
        if (response.status === "success") {
          setMeta({
            title: response.data.meta_title,
            Keywords: response.data.meta_keywords,
            description: response.data.meta_description,
          });
        }
      } catch (err) {
        throw err;
      }
    };

    useEffect(() => {
      const handleComponentDidMount = async () => {
        window.scroll(0, 0);
        await getmetaData("home");
        await getPageBlock("home");
      };
      handleComponentDidMount();
      return () => {
        localStorage.removeItem("prevurl");
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Home.Provider value={{ type: "HOME", data, meta }}>
        {children}
      </Home.Provider>
    );
  }
  export function useHomeCtx() {
    return useContext(Home);
  }
