
class Request {
  async sendApiRequest(url, method, setauth, body, is_pass_query_string = false) {

    const requestOptions = {
      method: method,

      headers: window.user?.data?.auth_token
        ? {
          "Cache-Control": "public, max-age=604800, immutable",
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip",
          Accept: "application/json",
          AUTHTOKEN: window.user?.data?.auth_token,
          "guest-user-id": localStorage.getItem("custumer_id"),
          currency: localStorage.getItem("currency") || "INR",
        }
        : {
          "Cache-Control": "public, max-age=604800, immutable",
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip",
          Accept: "application/json",
          "guest-user-id": localStorage.getItem("custumer_id"),
          currency: localStorage.getItem("currency") || "INR",
        },
      body: JSON.stringify(body),
    };
    if (method === "DELETE") {
      delete requestOptions.body;
    }
    if (method === "GET") {
      delete requestOptions.body;
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASEURL + url + (is_pass_query_string === true ? "?" + new Date().toLocaleString() : ""),
        requestOptions
      );
      let body = await response.text();
      if (response.status !== 200) {
        throw body;
      }
      const data = body.includes("{") ? JSON.parse(body) : body;
      return data;
    } catch (e) {
      throw e;
    }
  }



  titleCase(str) {
    var splitStr = str?.toLowerCase()?.split(" ");
    for (var i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1);
    }
    return splitStr?.join(" ");
  }

  alphabet = (num) => {
    const letter = String.fromCharCode(num + "A".charCodeAt(0));
    return letter;
  };

  time(duration) {
    var date = new Date(duration * 1000);
    var hh = date.getUTCHours();
    var mm = date.getUTCMinutes();
    var ss = date.getSeconds();
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    var t = hh + ":" + mm + ":" + ss;
    return t;
  }

  formatTime(time_string) {
    const date = new Date(time_string);
    var hh = date.getHours();
    var mm = date.getMinutes();

    var ampm = "AM";
    if (hh >= 12) {
      ampm = "PM";
    }
    if (hh === 0) {
      hh = 12;
    }
    else if (hh > 12) {
      hh -= 12;
    }

    return hh.toString().padStart(2, '0') + ":" + mm.toString().padStart(2, '0') + " " + ampm;
  }
  formatDate(date_string) {
    const date = new Date(date_string);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  formatFullDate(date_string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(date_string);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.getMonth(); // Months are zero-based
    const year = date.getFullYear();
    return `${day} ${months[month]}, ${year}`;
  }

  formatFullDateTime(date_string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(date_string);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.getMonth(); // Months are zero-based
    const year = date.getFullYear();

    const time = this.formatTime(date_string);
    return `${day} ${months[month]}, ${year} ` + time;
  }
  formatLLDate(date_string) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const date = new Date(date_string);
    const day = date.getDate().toString().padStart(2, '0');
    const month = date.getMonth(); // Months are zero-based
    const year = date.getFullYear();
    return `${months[month]} ${day}, ${year}`;
  }

  nameToInitials(fullName) {
    const namesArray = fullName && fullName?.trim().split(" ");
    if (namesArray?.length === 1) return `${namesArray[0].charAt(0)}`;
    else
      return `${namesArray[0].charAt(0)}${namesArray[
        namesArray?.length - 1
      ].charAt(0)}`;
  }
}

export default new Request();
