import { createContext, useContext, useState, useEffect } from "react";
import MetaService from "../../Services/MetaServices/MetaService";
const ServiceCtx = createContext();
export function ServiceCtxProvider({ children }) {
  const metaService = new MetaService();
  const [data, setData] = useState({});
  const [meta, setMeta] = useState({
    title: "",
    keywords: "",
    description: "",
    breadcrumb: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getmetaData("service");
    getPageBlock("service");
  }, []);

  const getmetaData = async () => {
    try {
      let response = await metaService.service("service");
      if (response.status === "success") {
        setData(response.data);
      }
    } catch (err) {
      throw err;
    }
  };

  const getPageBlock = async () => {
    try {
      let response = await metaService.getMetadetail("service");
      if (response.status === "success") {
        setMeta({
          title: response.data.meta_title,
          Keywords: response.data.meta_keywords,
          description: response.data.meta_description,
          breadcrumb: response.data.breadcrumb,
        });
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <ServiceCtx.Provider value={{ type: "SERVICES", data, meta }}>
      {children}
    </ServiceCtx.Provider>
  );
}
export function useServiceCtx() {
  return useContext(ServiceCtx);
}
